import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"

const AboutUsContainer = styled.div`
  margin-top: 1rem;
  min-height: 80vh;
  h1 {
    text-align: center;
  }
`

const AboutUs = () => {
  return (
    <Layout>
      <AboutUsContainer>
        <h1>About Us</h1>
      </AboutUsContainer>
    </Layout>
  )
}

export default AboutUs
